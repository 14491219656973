<template lang="html">
  <v-container pa-0 fluid ref="container">
    <v-row>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-model-table model-name="invoices" model-title="ประวัติการชำระเงิน"
          :model-api="['models','Accounting','AccountingInvoices']"
          :model-api-search="invoiceQuery"
          model-key="invoiceId"
          :api-with="['patient','payments','insurance']"
          api-order-by="invoiceId"
          :headers="headersTodayInvoices"
          :insertable="false"
          expandable
          searchable
        >
          <template v-slot:item.isVoid="props">
            <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
            <v-icon v-else>mdi-text-box-check-outline</v-icon>
          </template>
          <template v-slot:item.created_at="props">
            <v-label-datetime :date-time="props.item.created_at" short-date-time></v-label-datetime>
          </template>
          <template v-slot:item.patient.name_th="props">
            <v-label-name :name-data="props.item.patient.name_th"></v-label-name>
          </template>
          <template v-slot:item.insurance="props">
            <template v-if="props.item.insurance">
              <v-label-master group-key="payerType" :item-code="props.item.insurance.payerType"></v-label-master>
              <label v-if="props.item.insurance.payer">({{props.item.insurance.payer.payerName}})</label>
            </template>
            <template v-else>เงินสด</template>
          </template>
          <template v-slot:item.amount="props">
            {{ props.item.amount.toFixed(2) }}
          </template>
          <template v-slot:item.amountOutstanding="props">
            <label class="font-weight-medium red--text text-darken-1">{{ props.item.amountOutstanding.toFixed(2) }}</label>
          </template>
          <template v-slot:item.invoiceAction="props">
            <v-btn v-permission="'cashier-export'" @click="exportInvoice(props.item.invoiceId)">Export</v-btn>
            <v-btn v-permission="'cashier-invoice-rebuild'" @click="rebuildInvoice(props.item.invoiceId)">Rebuild</v-btn>&nbsp;
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on">พิมพ์</v-btn>
              </template>
              <v-list>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_insurance')">
                  <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_summary_cgd')">
                  <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_insurance')">
                  <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd')">
                  <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_detail_cgd_excess')">
                  <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_en')">
                  <v-list-item-title>English (Summary)</v-list-item-title>
                </v-list-item>
                <v-list-item @click="printDocument(props.item.documentId,'invoice_en_detail')">
                  <v-list-item-title>English (Detail)</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:expanded-item="{ headers,item }">
            <td :colspan="headers.length" class="pa-0">
              <v-enhance-table
                :items="item.payments"
                :headers="paymentHeader"
              >
                <template v-slot:item.isVoid="props">
                  <v-icon color="red" v-if="!!props.item.isVoid">mdi-file-remove-outline</v-icon>
                  <v-icon v-else>mdi-text-box-check-outline</v-icon>
                </template>
                <template v-slot:item.paymentAction="props">
                  <v-btn v-permission="'cashier-export'" @click="exportPayment(props.item.receiptId)">Export</v-btn>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on">พิมพ์</v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_insurance')">
                        <v-list-item-title>แบบสรุป (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_summary_cgd')">
                        <v-list-item-title>แบบสรุป (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_insurance')">
                        <v-list-item-title>แบบรายละเอียด (ประกัน)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd')">
                        <v-list-item-title>แบบรายละเอียด (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_detail_cgd_excess')">
                        <v-list-item-title>แบบแสดงส่วนเกิน (กรมบัญชีกลาง)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_en')">
                        <v-list-item-title>English (Summary)</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="printDocument(props.item.documentId,'receipt_en_detail')">
                        <v-list-item-title>English (Detail)</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-enhance-table>
            </td>
          </template>
        </v-model-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from 'tantee-common/lib/api'
import patientLoader from '@/components/mixins/patientLoader'
import printDocument from '@/components/mixins/printDocument'
import {isUndefined} from 'lodash'

export default {
  mixins: [patientLoader,printDocument],
  data: ()=>({
    headersTodayInvoices: [
      {
        text: '',
        value: 'isVoid',
        width: '20px',
      },
      {
        text: 'Invoice Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Invoice ID',
        value: 'invoiceId',
        class: 'body-2'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'patient.name_th',
        class: 'body-2'
      },
      {
        text: 'สิทธิ์/ประกัน',
        value: 'insurance',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงิน',
        value: 'amount',
        class: 'body-2'
      },
      {
        text: 'ค้างชำระ',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
      {
        text: 'Action',
        value: 'invoiceAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
    paymentHeader: [
      {
        text: '',
        value: 'isVoid',
        width: '20px',
      },
      {
        text: 'Receipt Date',
        value: 'created_at',
        class: 'body-2'
      },
      {
        text: 'Receipt ID',
        value: 'receiptId',
        class: 'body-2'
      },
      {
        text: 'Cashier ID',
        value: 'cashierId',
        class: 'body-2'
      },
      {
        text: 'Method',
        value: 'paymentMethod',
        class: 'body-2'
      },
      {
        text: 'Detail',
        value: 'paymentDetail',
        class: 'body-2'
      },
      {
        text: 'จำนวนเงินค้างชำระ',
        value: 'amountDue',
        class: 'body-2'
      },
      {
        text: 'ชำระครั้งนี้',
        value: 'amountPaid',
        class: 'body-2 font-weight-bold green--text text--darken-3'
      },
      {
        text: 'ค้างชำระ',
        value: 'amountOutstanding',
        class: 'body-2 font-weight-bold red--text text-darken-1',
      },
      {
        text: 'Action',
        value: 'paymentAction',
        align: 'center',
        sortable: false,
        class: 'body-2'
      }
    ],
  }),
  computed: {
    invoiceQuery() {
      return {"hn": this.currentPatient.hn}
    },
  },
  methods: {
    rebuildInvoice(invoiceId,callback) {
      let postData = {
        invoiceId: invoiceId,
      }
      let invoicePromise = api.post(['Encounter','TransactionController','rebuildInvoice'],postData).then((returnData)=>{
        if (returnData.success) {
          this.printDocument(returnData.data)
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Rebuild invoice(s)',invoicePromise)
    },
    exportInvoice(invoiceId,callback) {
      let postData = {
        invoiceId: invoiceId,
      }
      let invoicePromise = api.post(['Export','ExportController','ExportInvoiceId'],postData).then((returnData)=>{
        if (returnData.success) {
          this.$notify('Export ข้อมูลเรียบร้อย')
        } else {
          this.$notify('ไม่สามารถ Export ข้อมูลได้',{
            title: 'Error',
            icon: 'warning',
            color: 'warning',
          })
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Export invoice',invoicePromise)
    },
    exportPayment(receiptId,callback) {
      let postData = {
        receiptId: receiptId,
      }
      let paymentPromise = api.post(['Export','ExportController','ExportPaymentId'],postData).then((returnData)=>{
        if (returnData.success) {
          this.$notify('Export ข้อมูลเรียบร้อย')
        } else {
          this.$notify('ไม่สามารถ Export ข้อมูลได้',{
            title: 'Error',
            icon: 'warning',
            color: 'warning',
          })
        }
        if (!isUndefined(callback)) callback()
      })
      .catch((e)=>void e)
      this.$loading('Export payment',paymentPromise)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
